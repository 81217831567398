import styled from "styled-components";
import CONSTANTS from "config/constants";

import { SocialMediaFooterStyledProps as Props } from "./SocialMediaFooter.types";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const SocialMediaFooterStyled = styled.div<Props>`
  .SocialMediaFooter {
    &__main-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__line-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;

      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__line {
      border-top: 0.2rem dotted var(--palette-brown-s42-l93);
      width: 60%;

      @media (max-width: ${tablet}px) {
      }
    }

    &__logo-and-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 2rem 30rem;

      @media (max-width: ${tablet}px) {
        margin: 2rem;
      }
    }

    &__social-media-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 24rem;

      @media (max-width: ${mobile}px) {
        min-width: 0rem;
      }
    }

    &__text {
      font-size: 1.6rem;
      font-weight: 600;
      padding: 0.4rem;
      text-decoration: none;
      color: inherit;

      @media (max-width: ${mobile}px) {
        margin: 0.6rem;

        &:first-child {
          margin-right: 0rem;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__social-media {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 6rem;

      @media (max-width: ${mobile}px) {
        min-width: 5.2rem;
      }
    }

    &__logo {
      margin-right: 6rem;

      @media (max-width: ${tablet}px) {
        margin-right: 0;
      }
    }

    &__instagram,
    &__facebook {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .Pocket__header__title {
    max-width: 41.6rem;
    overflow: initial;
    text-overflow: initial;
    white-space: initial;
  }
`;

export default SocialMediaFooterStyled;
