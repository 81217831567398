export const defaultBreadcrumb = [
  {
    name: "Home",
    path: "/"
  },
  {
    name: "Preguntas frecuentes",
    path: "/frequent-questions"
  }
];

export const frequentQuestions = [
  {
    id: 0,
    question: "¿Sabes cuáles son las 5 libertades del bienestar animal?",
    answers: [
      "• Libre de hambre, de sed y de desnutrición",
      "• Libre de temor y de angustia",
      "• Libre de molestias físicas y térmicas",
      "• Libre de dolor, de lesión y de enfermedad",
      "• Libre de manifestar un comportamiento natural"
    ]
  },
  {
    id: 1,
    question: "¿Sabes qué es bienestar animal?",
    answers: [
      "Es el modo en que un animal interacciona de forma positiva con su entorno. Un animal está en buenas condiciones de bienestar si está sano, cómodo, bien alimentado, seguro, puede expresar formas innatas de comportamiento y si no padece sensaciones desagradables de dolor, miedo o desasosiego. "
    ]
  },
  {
    id: 2,
    question: "¿En qué consiste una adopción?",
    answers: [
      "Es el proceso acoger un animal de compañía por medio de particulares, protectoras o refugios, con motivo de lograr cubrir las necesidades básicas como la alimentación, la higiene, la salud y la recreación para el animal. "
    ]
  },
  {
    id: 3,
    question: "¿Sabes qué es la esterilización animal?",
    answers: [
      "“Es una intervención quirúrgica que elimina la capacidad reproductiva en hembras y machos caninos y felinos. Se realiza bajo anestesia general y consiste, en el caso de las hembras, en la extirpación de los ovarios y el útero, en el caso de los machos, en la extirpación de los testículos.  Es considerada la mejor estrategia para controlar la natalidad de nuevos individuos caninos y felinos, evitar su maltrato, abandono y existencia en condiciones inadecuadas. Además, ayuda a prevenir y controlar problemas de salud pública (enfermedades zoonóticas), conlleva beneficios de salud para los animales y mejora las condiciones de tenencia responsable para sus cuidadores.” (Protocolo de esterilización canina y felina, 2015)."
    ]
  },
  {
    id: 4,
    question: "¿Sabes qué es un animal mestizo?",
    answers: [
      "Los gatos y perros mestizos son el resultado del cruce entre diferentes razas e incluso con otros mestizos, el resultado es un compañero con características únicas e irrepetibles. Tu compañero mestizo será único en el mundo. "
    ]
  },
  {
    id: 5,
    question: "VIF o SIDA felino",
    answers: [
      {
        title: "¿Qué es la VIF o sida felino?",
        description:
          "VIF significa virus de inmunodeficiencia felina. Por lo general, el VIF provoca un debilitamiento del sistema inmunológico del gato. Sólo los gatos pueden contraer FIV. Las personas y los perros no pueden. No tiene relación con el sida en humanos. "
      },
      {
        title: "¿Cómo se contagian los gatos con VIF?",
        description:
          "La vía de infección más común es una herida profunda por mordedura de un gato positivo a otro gato. También se puede transmitir a través de la sangre, en el útero y de la leche de una gata infectada. Muchos gatos VIF positivos y gatos VIF negativos viven juntos en la misma casa durante años sin transmitir el virus a los gatos no infectados."
      },
      {
        title: "¿Cuáles son los signos de la infección por VIF?",
        description:
          "No hay signos específicos de infección por VIF. Los gatos VIF positivos tienen un sistema inmunológico más débil, por lo que son más propensos a contraer infecciones. Aparte de eso, los gatos VIF positivos tienden a llevar una vida normal y una duración normal de la vida."
      },
      {
        title: "¿Cómo sé si mi gato tiene VIF?",
        description:
          "No hay signos obvios de VIF, por lo que la única forma de saberlo es mediante un análisis de sangre. La prueba de detección más común es una prueba ELISA (a menudo llamada prueba SNAP) realizada por su veterinario. "
      },
      {
        title: "¿Se puede tratar el VIF?",
        description:
          "No existen tratamientos probados para librar a un gato del VIF. La mayoría de los gatos positivos al VIF manejan bien la enfermedad, pero es importante concentrarse en el tratamiento de las enfermedades secundarias."
      },
      {
        title: "¿Qué se puede hacer para prevenir la propagación del VIF?",
        description:
          "Los gatos deben permanecer en el interior, para que no peleen con un gato VIF positivo. No existe vacuna todavía. "
      },
      {
        title: "¿Pueden los gatos VIF negativos y VIF positivos vivir juntos?",
        description:
          "Sí, siempre y cuando los gatos se lleven bien y no peleen. La esterilización o castración también reducirá cualquier riesgo."
      },
      {
        title: "¿Pueden los gatos VIF positivos tener una vida buena y larga?",
        description:
          "Sí, los gatos VIF positivos pueden llevar una vida normal, tanto en calidad como en duración. Solo necesitan ser monitoreados para detectar infecciones y problemas dentales. Pero si están bien cuidados, pueden ser animalitos saludables, felices y maravillosos."
      }
    ]
  },
  {
    id: 6,
    question: "Leucemia felina",
    answers: [
      {
        title: "¿Qué es FeLV?",
        description:
          "FeLV significa virus de la leucemia felina. Como su nombre lo indica, es una infección viral de los gatos que afecta el sistema inmunológico y la médula ósea de un gato. No es como el cáncer en humamos."
      },
      {
        title: "¿Cómo se contagian los gatos con FeLV?",
        description:
          "El virus generalmente se transmite de gatos infectados a gatos no infectados a través del contacto personal cercano, generalmente con saliva. Se puede contagiar al acicalarse, compartir platos de comida, morder y otras formas de contacto cercano. También se puede transmitir de una gata a un gatito en el útero o a través de la leche. Si un gato FeLV positivo se aloja en una habitación separada de un gato FeLV negativo, es poco probable que se produzca la transmisión. Para estar seguro, los tazones de comida y agua no deben compartirse."
      },
      {
        title: "¿Pueden los humanos contraer FeLV?",
        description: "Absolutamente no."
      },
      {
        title: "¿Cuáles son los signos de la infección por FeLV?",
        description:
          "No hay signos específicos de infección por FeLV. En general, los gatos con FeLV tienen un sistema inmunológico más débil, por lo que son más propensos a infecciones. Los gatos adultos pueden vivir muchos años saludables con la enfermedad. Lamentablemente, a los gatitos que contraen la enfermedad a menudo no les va tan bien. Alrededor del 80% de los gatitos con FeLV no viven más de tres años."
      },
      {
        title: "¿Cómo sé si mi gato tiene FeLV?",
        description:
          "La única forma de saber si su gato es FeLV positivo es hacer que su veterinario realice una prueba de ELISA. Sin embargo, dar positivo solo significa que el virus está circulando en la sangre del gato. No significa que el gato se infectará permanentemente. Es posible que un gato combata la infección. Los resultados pueden ser difíciles de interpretar definitivamente en algunos casos debido a la complejidad de esta enfermedad. Te recomendamos hablarlo con tu veterinario."
      },
      {
        title: "¿Se puede tratar el FeLV?",
        description:
          "No existe cura para el FeLV, por lo que la mayoría de los tratamientos de gatos positivos para FeLV implican cuidados de apoyo. Debido a que los gatos FeLV-positivos tienen sistemas inmunológicos más débiles, necesitan que estemos más pendiente a otras enfermedades o infecciones que les pueda dar."
      },
      {
        title: "¿Qué se puede hacer para prevenir la propagación del FeLV?",
        description:
          "Dado que no existe cura, la prevención es el mejor tratamiento para el FeLV. Por lo tanto, mantener a sus gatos en el interior debería evitar la exposición. Además, todos los gatos que ingresan a la casa deben ser evaluados antes de presentárselos a sus gatos. Existe una vacuna para FeLV. Si tu gato sale a la calle o si traes gatos a tu casa que no puedes analizar o aislar, debes vacunar a tu gato, especialmente a una edad temprana."
      },
      {
        title:
          "¿Pueden los gatos FeLV negativos y FeLV positivos vivir juntos?",
        description:
          "Las personas que tienen gatos pueden decidir que quieren compartir su hogar con gatos positivos y negativos. Una opción segura es mantener vacunados a los gatos negativos y también prevenir el contacto prolongado con gatos positivos (manteniéndolos en habitaciones o partes de la casa separadas)."
      },
      {
        title: "¿Pueden los gatos FeLV positivos tener una buena vida?",
        description:
          "Los gatos positivos para FeLV pueden vivir vidas perfectamente felices. Las personas que tienen gatos con FeLV positivo solo deben saber que esos gatos pueden tener una vida útil más corta y que deben ser llevados a un veterinario tan pronto como se detecte un problema. Muchas personas que adoptan y cuidan a gatos positivos para FeLV lo describen como una experiencia positiva y profundamente gratificante, y que con mucho gusto volverían a hacerlo."
      }
    ]
  }
];
