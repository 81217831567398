import styled from "styled-components";
import CONSTANTS from "config/constants";

import { FrequentQuestionsAccordionStyledProps as Props } from "./FrequentQuestionsAccordion.types";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const FrequentQuestionsAccordionStyled = styled.div<Props>`
  .FrequentQuestionsAccordion {
    &__container {
      padding-bottom: 0.4rem;
    }

    &__text-box {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__heading-primary {
      color: var(--palette-cream-s42-l93);
      font-size: 2.4rem;
      margin-bottom: 2rem;
      font-weight: 800;

      @media (max-width: ${tablet}px) {
        font-size: 2.4rem;
      }
    }

    &__accordion {
      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 3rem;
        margin-left: 3rem;

        @media (max-width: ${tablet}px) {
          display: block;
        }
      }

      &-title {
        font-size: 2rem;
        font-weight: 600;

        &::after {
          content: "";
          width: 100%;
          display: flex;
          margin-bottom: 1rem;
          border-top: 0.2rem dotted var(--palette-gray-s0-l40-a40);
        }

        @media (max-width: ${tablet}px) {
          font-size: 1.6rem;
          margin: 0;
        }
      }

      &-content {
        font-weight: 400;
        font-size: 1.4rem;
        padding: 1.6rem;
        padding-top: 1rem;
        padding-right: 0;
        animation: AppearAbove 2s;
      }
    }

    &__text {
      color: var(--palette-black);
      line-height: 2rem;
      font-size: 1.4rem;
      max-width: 40rem;

      @media (max-width: ${tablet}px) {
        max-width: unset;
        margin-right: 4rem;
      }
    }

    &__subtitle {
      color: var(--palette-black);
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.8rem;
      margin-top: 1rem;
      margin-bottom: 1.6rem;
    }
  }

  .Pocket__header__title {
    max-width: 40rem;
    overflow: initial;
    text-overflow: initial;
    white-space: initial;
    margin-bottom: 1rem;
    font-size: 1.6rem;

    @media (max-width: ${tablet}px) {
      max-width: 30rem;
    }

    @media (max-width: ${mobile}px) {
      max-width: 20rem;
    }
  }

  @keyframes AppearAbove {
    0% {
      transform: translateX(-10rem);
      opacity: 0;
    }

    100% {
      transform: translate(0);
      opacity: 1;
    }
  }
`;

export default FrequentQuestionsAccordionStyled;
