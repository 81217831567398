import styled from "styled-components";
import CONSTANTS from "config/constants";

import { FooterStyledProps as Props } from "./Footer.types";

const { tablet } = CONSTANTS.BREAKPOINTS;

const FooterStyled = styled.div<Props>`
  background: ${({ showEllipse }) =>
    showEllipse
      ? `linear-gradient(
    to top,
    var(--palette-purple-s66-l84) 0%,
    var(--palette-purple-s66-l84) 75%,
    var(--palette-cream-s42-l93) 75%,
    var(--palette-cream-s42-l93) 100%
  )`
      : `transparent`};

  @media (max-width: ${tablet}px) {
    background: ${({ showEllipse }) =>
      showEllipse
        ? `linear-gradient(
      to top,
      var(--palette-purple-s66-l84) 0%,
      var(--palette-purple-s66-l84) 70%,
      var(--palette-cream-s42-l93) 70%,
      var(--palette-cream-s42-l93) 100%
    )`
        : `transparent`};
  }

  .Footer {
    &__ellipse {
      width: 100%;
      height: 18rem;
      clip-path: ellipse(60% 40% at 50% 100%);
      background-color: ${({ showEllipse }) =>
        showEllipse ? `var(--palette-purple-s66-l84)` : `transparent`};

      @media (max-width: ${tablet}px) {
        clip-path: ellipse(60% 18% at 50% 100%);
      }
    }

    &__content {
      background-color: ${({ showEllipse }) =>
        showEllipse ? `var(--palette-purple-s66-l84)` : `transparent`};
      min-height: ${({ showEllipse }) => (showEllipse ? `50rem` : `0`)};
      padding-bottom: ${({ showEllipse }) => (showEllipse ? `unset` : `2rem`)};
    }
  }
`;

export default FooterStyled;
