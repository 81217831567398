import React from "react";
import { useRouter } from "next/router";
import Link from "next/link";

import Styles from "./SocialMediaFooter.styles";
import { SocialMediaFooterProps as Props } from "./SocialMediaFooter.types";
import useWindowSize from "hooks/useWindowSize";
import CONSTANTS from "config/constants";

import LogoSVG from "../../../../../public/assets/images/Logos/mosumi.svg";
import LogoMobileSVG from "../../../../../public/assets/images/Logos/mosumi-white-mobile.svg";
import FacebookSVG from "../../../../../public/assets/images/SocialMediaLogos/facebook-dark.svg";
import InstagramSVG from "../../../../../public/assets/images/SocialMediaLogos/instagram-dark.svg";

const { BREAKPOINTS } = CONSTANTS;
const { mobile } = BREAKPOINTS;
const PDF =
  "/assets/documents/2022-AGO-MOTRANSA-TERMINOS_Y_CONDICIONES_Adopcion_Animal.pdf";

const SocialMediaFooter: React.FC<Props> = props => {
  const { className } = props;
  const { push } = useRouter();
  const { width } = useWindowSize();
  const isMobile = width <= mobile;

  return (
    <Styles className={`SocialMediaFooter ${className}`}>
      <div className="SocialMediaFooter__line-container">
        <div className="SocialMediaFooter__line" />
      </div>
      <div className="SocialMediaFooter__main-container">
        <div className="SocialMediaFooter__logo-and-content">
          {isMobile ? (
            <LogoMobileSVG className="SocialMediaFooter__logo" />
          ) : (
            <LogoSVG className="SocialMediaFooter__logo" />
          )}
          <div className="SocialMediaFooter__social-media-container">
            <Link href={PDF} passHref>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="SocialMediaFooter__text"
              >
                Legales
              </a>
            </Link>
            <p
              className="SocialMediaFooter__text"
              onClick={() => push("/profile/about-us")}
            >
              Nosotros
            </p>
            <div className="SocialMediaFooter__social-media">
              <>
                <FacebookSVG
                  className="SocialMediaFooter__facebook"
                  onClick={() =>
                    window.open(
                      "https://web.facebook.com/Adopci%C3%B3n-Animal-104500682296364"
                    )
                  }
                />
                <InstagramSVG
                  className="SocialMediaFooter__instagram"
                  onClick={() =>
                    window.open("https://www.instagram.com/adopcion_.animal")
                  }
                />
              </>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

SocialMediaFooter.defaultProps = {
  className: ""
};

export default SocialMediaFooter;
