import React from "react";

import Styles from "./Footer.styles";
import { FooterProps as Props } from "./Footer.types";
import SocialMediaFooter from "components/mosumi/utilComponents/SocialMediaFooter/SocialMediaFooter";
import FrequentQuestionsAccordion from "components/mosumi/utilComponents/FrequentQuestionsAccordion/FrequentQuestionsAccordion";

const Footer: React.FC<Props> = props => {
  const { className, showEllipse } = props;

  return (
    <Styles showEllipse={!!showEllipse} className={`Footer ${className}`}>
      {showEllipse ? <div className="Footer__ellipse" /> : null}
      <div className="Footer__content">
        {showEllipse ? <FrequentQuestionsAccordion /> : null}
        <SocialMediaFooter />
      </div>
    </Styles>
  );
};

Footer.defaultProps = {
  className: ""
};

export default Footer;
