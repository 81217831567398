import React from "react";
import { Accordion } from "artisn-ui-react";

import Styles from "./FrequentQuestionsAccordion.styles";
import { FrequentQuestionsAccordionProps as Props } from "./FrequentQuestionsAccordion.types";
import { frequentQuestions } from "../../../frequentQuestions/FrequentQuestions/FrequentQuestions.helpers";

const { Section } = Accordion;

const FrequentQuestionsAccordion: React.FC<Props> = props => {
  const { className } = props;

  return (
    <Styles className={`FrequentQuestionsAccordion ${className}`}>
      <div className="FrequentQuestionsAccordion__container">
        <div className="FrequentQuestionsAccordion__text-box">
          <h3 className="FrequentQuestionsAccordion__heading-primary">
            Preguntas frecuentes
          </h3>
        </div>

        <div className="FrequentQuestionsAccordion__accordion-container">
          <div className="FrequentQuestionsAccordion__accordion">
            <Accordion
              className="FrequentQuestionsAccordion__accordion-item"
              single
            >
              {frequentQuestions.map(question => (
                <Section
                  className="FrequentQuestionsAccordion__accordion-title"
                  title={question.question}
                  key={question.id}
                >
                  <div className="FrequentQuestionsAccordion__accordion-content">
                    <ul>
                      {question.answers?.map((answer, index) => {
                        if (typeof answer === "string") {
                          return (
                            <p
                              className="FrequentQuestionsAccordion__text"
                              key={index}
                            >
                              {answer}
                            </p>
                          );
                        }
                        return (
                          <div key={index}>
                            <h4 className="FrequentQuestionsAccordion__subtitle">
                              {answer.title}
                            </h4>
                            <p className="FrequentQuestionsAccordion__text">
                              {answer.description}
                            </p>
                          </div>
                        );
                      })}
                    </ul>
                  </div>
                </Section>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </Styles>
  );
};

FrequentQuestionsAccordion.defaultProps = {
  className: ""
};

export default FrequentQuestionsAccordion;
